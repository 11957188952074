<template>
    <b-row>
        <b-col cols="12">
            <div
                class="d-sm-flex justify-content-between align-items-center mb-4"
            >
                <feather
                    type="arrow-left-circle"
                    v-b-popover.hover.top="'Presione esc'"
                    role="button"
                    class="text-secondary"
                    size="2rem"
                    @click="back"
                ></feather>
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        class="d-flex align-items-center"
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
        >
            <b-card class="mb-4 w-100">
                <div>
                    <b-form class="p-5" v-hotkey="keymap">
                        <div class="d-flex">
                            <div>
                                <h3 class="mb-2">
                                    Datos de la cuenta de email
                                </h3>
                                <div class="text-gray mb-4">
                                    Por favor registre los datos de la cuenta de
                                    email para continuar
                                </div>
                            </div>
                        </div>
                        <b-alert
                            :show="showAlert"
                            variant="warning"
                            class="mb-5"
                        >
                            <h4 class="alert-heading">Atención!</h4>
                            <p>
                                La configuración de email para cuentas del
                                proveedor de gmail requieren la creación de una
                                clave de aplicación, para crear esta clave por
                                favor acceda al siguiente en lace y luego pegue
                                su clave de aplicación en el campo contraseña de
                                este formulario.
                            </p>
                            <hr />
                            <p class="mb-0">
                                <a
                                    href="https://security.google.com/settings/security/apppasswords"
                                    target="_blank"
                                    >Crear clave de aplicación de Google</a
                                >.
                            </p>
                        </b-alert>
                        <b-row>
                            <b-col cols="12" md="8" lg="8" xl="8">
                                <b-form-group>
                                    <label for="descripcion"
                                        >Descripción
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="descripcion"
                                        type="text"
                                        placeholder="Ej: Mi cuenta personal de email"
                                        v-model="
                                            $v.configEmail.descripcion.$model
                                        "
                                        :state="validateState('descripcion')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.configEmail.descripcion
                                                .$anyError
                                        "
                                    >
                                        ({{
                                            $v.configEmail.descripcion.$model
                                                .length
                                        }}
                                        /
                                        {{
                                            $v.configEmail.descripcion.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.configEmail.descripcion
                                                .maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.configEmail.descripcion.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            $v.configEmail.descripcion.required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="tipo"
                                        >Tipo de servicio
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <jautocomplete
                                        id="tipo"
                                        :items="tipo_servicios"
                                        item-text="descripcion"
                                        item-value="id"
                                        :disabled="read_only"
                                        open-on-focus
                                        v-model="$v.configEmail.tipo.$model"
                                        :state="validateState('tipo')"
                                    />

                                    <!-- <b-form-select
                                        id="tipo"
                                        :options="tipo_servicios"
                                        text-field="descripcion"
                                        value-field="id"
                                        v-model="$v.configEmail.tipo.$model"
                                        :state="validateState('tipo')"
                                        :disabled="read_only"
                                    >
                                        <template #first>
                                            <b-form-select-option
                                                :value="''"
                                                disabled
                                                >Seleccione</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select> -->
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.configEmail.tipo.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <b-form-group>
                                    <label for="email"
                                        >Email
                                        <small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="email"
                                        type="text"
                                        placeholder="Ej: correo@provider.com"
                                        v-model="$v.configEmail.email.$model"
                                        :state="validateState('email')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.configEmail.email.$anyError"
                                    >
                                        ({{
                                            $v.configEmail.email.$model.length
                                        }}
                                        /
                                        {{
                                            $v.configEmail.email.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.configEmail.email.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.configEmail.email.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.configEmail.email.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.configEmail.email.email"
                                    >
                                        Email inválido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6" lg="6" xl="6">
                                <label for="password"
                                    >Contraseña
                                    <small style="color: red">*</small></label
                                >
                                <b-input-group>
                                    <b-form-input
                                        id="password"
                                        :type="showPwd ? 'text' : 'password'"
                                        placeholder="Contraseña del email"
                                        v-model="$v.configEmail.password.$model"
                                        :state="validateState('password')"
                                        :disabled="read_only"
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            class="d-flex align-items-center"
                                            variant="outline-secondary"
                                            :disabled="
                                                configEmail.password.length ===
                                                    0 || read_only
                                            "
                                            @click="showPwd = !showPwd"
                                        >
                                            <feather
                                                :type="
                                                    showPwd ? 'eye-off' : 'eye'
                                                "
                                                size="1.1rem"
                                            ></feather>
                                        </b-button>
                                    </b-input-group-append>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.configEmail.password.minLength
                                        "
                                    >
                                        La contraseña debe tener al menos
                                        {{
                                            $v.configEmail.password.$params
                                                .minLength.min
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.configEmail.password.maxLength
                                        "
                                    >
                                        La contraseña debe tener máximo
                                        {{
                                            $v.configEmail.password.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.configEmail.password.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-input-group>
                                <div
                                    class="length-field mt-1"
                                    v-if="!$v.configEmail.password.$anyError"
                                >
                                    ({{ $v.configEmail.password.$model.length }}
                                    /
                                    {{
                                        $v.configEmail.password.$params
                                            .maxLength.max
                                    }})
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="port"
                                        >Puerto smtp<small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="port"
                                        type="number"
                                        placeholder="Ej: 5845"
                                        v-model="$v.configEmail.port.$model"
                                        :state="validateState('port')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.configEmail.port.$anyError"
                                    >
                                        ({{
                                            $v.configEmail.port.$model
                                                ? $v.configEmail.port.$model.toString()
                                                      .length
                                                : ''.length
                                        }}
                                        /
                                        {{
                                            $v.configEmail.port.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.configEmail.port.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.configEmail.port.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.configEmail.port.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="host"
                                        >Host smtp<small style="color: red"
                                            >*</small
                                        ></label
                                    >
                                    <b-form-input
                                        id="host"
                                        type="text"
                                        placeholder="Ej: smtp.gmail.com"
                                        v-model="$v.configEmail.host.$model"
                                        :state="validateState('host')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.configEmail.host.$anyError"
                                    >
                                        ({{ $v.configEmail.host.$model.length }}
                                        /
                                        {{
                                            $v.configEmail.host.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="!$v.configEmail.host.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.configEmail.host.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="!$v.configEmail.host.required"
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="4" lg="4" xl="4">
                                <b-form-group>
                                    <label for="clase"
                                        >Clase email<small style="color: red"
                                            >*</small
                                        ></label
                                    >

                                    <jautocomplete
                                        id="clase"
                                        :items="clases"
                                        item-text="descripcion"
                                        item-value="id"
                                        open-on-focus
                                        v-model="$v.configEmail.clase.$model"
                                        :state="validateState('clase')"
                                        :disabled="read_only"
                                    />

                                    <!-- <b-form-select
                                        id="clase"
                                        :options="clases"
                                        text-field="descripcion"
                                        value-field="id"
                                        v-model="$v.configEmail.clase.$model"
                                        :state="validateState('clase')"
                                        :disabled="read_only"
                                    >
                                        <template #first>
                                            <b-form-select-option
                                                :value="''"
                                                disabled
                                                >Seleccione</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select> -->
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="$v.configEmail.clase.$error"
                                    >
                                        Campo requerido
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="float-right">
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        class="d-flex align-items-center"
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import { mapGetters } from 'vuex'
import {
    removeListenerCommands,
    addListenerCommands,
    compareObjects
} from '@/utils/others'

const { API } = services

const defaultConfigEmail = () =>
    JSON.parse(
        JSON.stringify({
            enabled: true,
            descripcion: '',
            tipo: '',
            email: '',
            password: '',
            clase: '',
            host: '',
            port: '',
            empresa_id: null
        })
    )

export default {
    name: 'CrearConfiguracionEmail',
    mixins: [validationMixin],
    components: { jautocomplete },
    data: () => ({
        configEmail: defaultConfigEmail(),
        configEmailCopy: defaultConfigEmail(),
        showPwd: false,
        isLoading: false,
        isSaved: true,
        showAlert: false,
        tipo_servicios: [
            { descripcion: 'Gmail', id: 'gmail' },
            { descripcion: 'Outlook', id: 'outlook' },
            { descripcion: 'Hotmail', id: 'hotmail' },
            { descripcion: 'Yahoo', id: 'yahoo' },
            { descripcion: 'Corporativo', id: 'corporativo' }
        ],
        clases: [
            { descripcion: 'General', id: 'general' },
            { descripcion: 'Documento', id: 'documento' }
        ],
        disableTipoDocumento: false,
        read_only: false
    }),
    watch: {
        configEmail: {
            handler(newValue) {
                this.isSaved = compareObjects(this.configEmailCopy, newValue)
            },
            deep: true
        }
    },
    mounted() {
        addListenerCommands(this)
        setTimeout(() => {
            this.showAlert = true
        }, 2000)
        this.getParams()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        configEmail: {
            descripcion: {
                required,
                maxLength: maxLength(150)
            },
            tipo: {
                required
            },
            email: {
                required,
                maxLength: maxLength(150),
                email
            },
            password: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(50)
            },
            clase: {
                required,
                maxLength: maxLength(100)
            },
            host: {
                required,
                maxLength: maxLength(150)
            },
            port: {
                required,
                maxLength: maxLength(4)
            }
        }
    },
    methods: {
        getParams() {
            const { configuracion_email_id } = this.$route.params
            if (configuracion_email_id) {
                this.getConfiguracionEmailById(configuracion_email_id)
            }

            this.read_only = this.$route.meta.read_only
        },
        getConfiguracionEmailById(id) {
            // console.log('acá 2');
            API.POST({
                url: 'cartero/config-mail/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato);
                        this.configEmailCopy = { ...dato }
                        this.configEmail = { ...dato }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.configEmail.$invalid
            if (valid) {
                this.isLoading = true
                this.configEmail.empresa_id = this.getUltimaEmpresa.id
                const p_opc = this.configEmail.id ? 'UPDATE' : 'INSERT'
                API.POST({
                    url: 'cartero/config-mail/crud',
                    data: {
                        p_datajson: {
                            ...this.configEmail
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            this.back()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.configEmail.$touch()
            }
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.configEmail[key]
            return $dirty ? !$error : null
        },
        back() {
            this.$router.back()
        }
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 33 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>

<style></style>
